import React, {FC} from "react";
import Link from "next/link";
import {FormattedMessage} from "react-intl";
import {IMenuList} from "../../types/state";
import {IFooterAccount as IProps} from "./FooterNewsLetter/types";
import footerCls from "./Footer-scss/Footer.module.scss"
const FooterAccount: FC<IProps> = ({ pageProps}) => {
    const footerLinks = pageProps?.menus?.filter((item: { type: string }) => item.type === "footer")
    return (
        <>
            {
                pageProps?.hasTracking?.map((item, index) => (
                    // <div key={index} className="trackingNumber-fms">
                        <div key={index} className={footerCls["trackingNumber-fms"]}>
                        <Link href={item.href}>
                            <a>
                                <FormattedMessage
                                    id={item.id}
                                    defaultMessage={item.defaultMessage}
                                />
                            </a>
                        </Link>
                    </div>
                ))
            }
            {/*{*/}
            {/*    footerLinks*/}
            {/*        ?.sort((a: IMenuList, b: IMenuList) => a.position - b.position)*/}
            {/*        .map((link: IMenuList, index: number) => (*/}
            {/*            <div key={index} className={footerCls["footerLinks-fms"]}>*/}
            {/*            /!*<div key={index} className="footerLinks-fms" >*!/*/}
            {/*                <Link*/}
            {/*                    key={link.id}*/}
            {/*                    href={link.url_key ? link.url_key : `/page/${link.slug}`}*/}
            {/*                    target={link.url_key && "_blank"}*/}
            {/*                >*/}
            {/*                    <a>{link.name}</a>*/}
            {/*                </Link>*/}
            {/*            </div>*/}
            {/*        )*/}
            {/*    )*/}
            {/*}*/}
        </>
    );
}

export default FooterAccount;
